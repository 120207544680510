<template>
  <div class="loader-wrapper">
      <div class="inava-loader"></div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ReportsShared',
  data() {
    return {
    }
  },
  async mounted() {
    let reportId = this.$route.params.reportId
    let reprotInfo = await this.getReportInfoById(reportId)
    let attachments = reprotInfo.attachments ? reprotInfo.attachments : []
    let downloadParam = { attachments, reportId }
    await this.downloadAttachments(downloadParam)
    this.$router.push({name: this.getPathName()})
  },
  methods: {
    ...mapActions('reportsRepository', ['downloadAttachments', 'getReportInfoById']),
    ...mapActions('common', ['toggleFullPageLoader']),
    getPathName () {
      let pathName = 'reports-repository'
      if (this.$route.path.includes('equity')) {
        pathName = 'equityReports'
      } else if (this.$route.path.includes('fixed-income')) {
        pathName = 'fixedIncome'
      }
      return pathName
    }
  }
}
</script>

<style>

</style>